import { Typography } from "@mui/material";
import React from "react";
import { ImageDecoration } from "../ImageDecoration/ImageDecoration";
import { Box } from "@mui/material";

export const HeaderForm = () => {
  return (
    <Box
      sx={{
        pt: { xs: "3rem", sm: "5rem", md: "6rem" },
      }}
    >
      <ImageDecoration />
      <Typography variant="h2" sx={{ mb: "3rem" }}>
        Confirma tú asistencia
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: "1rem",
          fontWeight: "bold",
          color: "#8883b9",
        }}
      >
        El día de la boda será el 26 de mayo de 2023.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "30rem",
          margin: "0 auto",
          mb: "2rem",
          mt: "2rem",
        }}
      >
        Únete a nosotros mientras celebramos nuestra boda y usa el formulario
        para confirmar tú asistencia.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "30rem",
          margin: "0 auto",
        }}
      >
        Por favor confirma tú asistencia a más tardar el
      </Typography>
      <Typography
        variant="body1"
        sx={{
          maxWidth: "30rem",
          margin: "0 auto",

          fontWeight: "bold",
        }}
      >
        15 de abril de 2023
      </Typography>
    </Box>
  );
};
