import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDZeGFEnA2iyDBcfV28UvEaAXUDCstRmGY",
  authDomain: "boda-juli-y-cristian.firebaseapp.com",
  projectId: "boda-juli-y-cristian",
  storageBucket: "boda-juli-y-cristian.appspot.com",
  messagingSenderId: "681192290230",
  appId: "1:681192290230:web:f516a5dcba9ae53432b317",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);

export default db;
