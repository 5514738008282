import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

export const LocationInfo = () => {
  return (
    <Box
      id="ubicacion"
      sx={{
        pb: "15rem",
        pt: "5rem",
        backgroundColor: "#e3dcf1",
        display: "flex",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            mb: "5rem",
            mt: "5rem",
            textAlign: "center",
            fontSize: { xs: "2.4rem", md: "3.1rem" },
          }}
        >
          Ubicación
        </Typography>

        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h3" sx={{ mb: "2.5rem", textAlign: "center" }}>
              La ceremonia
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: "2rem",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                }}
              >
                <img src="/church.svg" alt="icon church" />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "20rem",
                  mt: "1rem",
                  textAlign: "center",
                }}
              >
                La boda se celebrará en la Parroquia Beato Mariano de Jesús Euse
                Hoyos en la Cra. 24B No 17 - 110, el poblado, Medellín a las
                4:00 pm.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "20rem",
                  textAlign: "center",
                  mt: "1rem",
                }}
              >
                La parroquia cuenta con parqueadero
              </Typography>

              <a href="https://goo.gl/maps/19btLyxKTVH9KuwE8">
                <Typography
                  position="relative"
                  variant="body1"
                  sx={{
                    color: "#fff",
                    mt: "1em",
                    textDecoration: "none",
                    zIndex: "99",
                    background: "#8883b9",
                    borderRadius: "12px",
                    padding: "0.5rem",
                  }}
                >
                  Ver en el mapa
                </Typography>
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h3" sx={{ mb: "2.5rem", textAlign: "center" }}>
              La recepción
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: "2rem",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                }}
              >
                <img src="/wine.svg" alt="icon wine" />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "20rem",
                  textAlign: "center",
                  mt: "1rem",
                }}
              >
                La recepción se realizará en el centro de eventos Cielo Alto en
                la Cra. 9E No 16A Sur - 104, Medellín
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "20rem",
                  textAlign: "center",
                  mt: "1rem",
                }}
              >
                La recepción cuenta con parqueadero
              </Typography>
              <a href="https://goo.gl/maps/EsuXK4dBLEhTC5qA6">
                <Typography
                  variant="body1"
                  sx={{
                    color: "#fff",
                    mt: "1em",
                    textDecoration: "none",
                    zIndex: "99",
                    background: "#8883b9",
                    borderRadius: "12px",
                    padding: "0.5rem",
                  }}
                >
                  Ver en el mapa
                </Typography>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
