import "./App.css";
import { Box } from "@mui/material";
import { CountdownTimer } from "../src/components/CountdownTimer/CountdownTimer";
import { FormSection } from "../src/components/FormSection/FormSection";
import { HistorySection } from "../src/components/HistorySection/HistorySection";
import { Layout } from "../src/components/Layout/Layout";
import { LocationInfo } from "../src/components/LocationInfo/LocationInfo";
import { GiftSection } from "../src/components/GiftSection/GiftSection";
import { Initial } from "./components/Initial/Initial";

function App() {
  return (
    <Layout>
      <Box sx={{ mt: "2.5rem" }}>
        {/* <Header imageUrlHeader={imageUrlHeader} /> */}
        <Initial />
        <CountdownTimer />
        <HistorySection />
        {/* <GallerySplide imageUrls={imageUrls} /> */}
        <GiftSection />
        <LocationInfo />
        <FormSection />
      </Box>
    </Layout>
  );
}

export default App;
