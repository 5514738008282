
import React from "react";
import { Box } from "@mui/material";

export const ImageDecoration = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: "11%", sm: "8%", md: "7%" },
        left: { xs: "35%", sm: "27%", md: "35%" },
        transform: "translate(-50%,-50%)",
        height: { xs: "auto", sm: "200px", md: "200px" },
        width: { xs: "450px", sm: "500px", md: "650px" },
      }}
    >
      <img src='/flowers.svg' alt="image" />
    </Box>
  );
};
