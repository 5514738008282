import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

export const GiftSection = () => {
  return (
    <>
      <Box
        id="regalos"
        sx={{
          pt: { xs: "3rem", sm: "5rem", md: "5rem" },
          pb: { xs: "3rem", sm: "1rem", md: "8rem" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: "3rem",
                mt: "5rem",
                fontSize: { xs: "2.4rem", md: "3.1rem" },
              }}
            >
              Regalos
            </Typography>
            <Typography
              variant="body1"
              sx={{
                maxWidth: "35em",
                margin: "0 auto",
              }}
            >
              Su presencia es lo mas importante en este día tan especial, pero
              si algo nos quieren regalar, en efectivo lo sabremos apreciar.
            </Typography>
            <img src="/sobre.png" alt="Lluvia de sobres" />
            <Typography
              variant="body1"
              sx={{
                maxWidth: "35em",
                margin: "0 auto",
                fontFamily:"BrittanySignature",
                fontSize: { xs: "2.4rem", md: "3.1rem" },
              }}
            >
              Lluvia de sobres
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};
