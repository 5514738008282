import { autocompleteClasses, Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import useCountdown from "../../hooks/useCountdown";
import Lottie from "lottie-react";
import stopwatch from "../../stopwatch.json";

const style = {
  height: 200,
};

const AnimationWatch = () => {
  return <Lottie animationData={stopwatch} style={style} />;
};

export const CountdownTimer = () => {
  // '10/05/2022 17:47' month/day/year time
  const countdown = useCountdown(new Date("/05/26/2023 16:00"));
  return (
    <Box
      id="cuando"
      sx={{
        pt: { xs: "7rem", sm: "5rem" },
      }}
    >
      <Box
        sx={{
          pb: "8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            textAlign: "center",
          }}
        >
          {/* <Typography
            variant="h1"
            sx={{
              fontFamily:"BrittanySignature",
              mb: "3rem",
              mt: "5rem",
              fontSize: { xs: "2.4rem", md: "4.1rem" },
            }}
          >
           Juliana & Cristian
          </Typography> */}
          <Box
            sx={{
              backgroundImage: `url(${"/separador3.svg"})`,
              height: { xs: "63px", md: "150px" },
              backgroundSize: "100% auto",
              //  width: 1,
              backgroundRepeat: "no-repeat",
              //  backgroundSize: "cover",
            }}
          ></Box>
          <AnimationWatch />
          <Typography
            variant="h2"
            sx={{
              mb: "3rem",
              mt: "5rem",
              fontSize: { xs: "2.4rem", md: "3.1rem" },
            }}
          >
            "26 de Mayo de 2023"
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: "35em",
              margin: "0 auto",
            }}
          >
            Se acerca uno de los días más feliz de nuestras vidas y estaremos
            muy emocionados de verte en:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "5rem" }}>
            {TimeBox(countdown.days, "Días")}
            <Typography
              variant="h2"
              sx={{
                mr: "5px",
                ml: "5px",
                fontSize: { xs: "2.4rem", md: "3.1rem" },
              }}
            >
              :
            </Typography>
            {TimeBox(countdown.hours, "Horas")}
            <Typography
              variant="h2"
              sx={{
                mr: "5px",
                ml: "5px",
                fontSize: { xs: "2.4rem", md: "3.1rem" },
              }}
            >
              :
            </Typography>
            {TimeBox(countdown.minutes, "Minutos")}
            <Typography
              variant="h2"
              sx={{
                mr: "5px",
                ml: "5px",
                fontSize: { xs: "2.4rem", md: "3.1rem" },
              }}
            >
              :
            </Typography>
            {TimeBox(countdown.seconds, "Segundos")}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

function TimeBox(type, label) {
  return (
    <div>
      <Typography
        variant="h2"
        sx={{ fontSize: { xs: "2.4rem", md: "3.1rem" } }}
      >
        {type}
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {label}
      </Typography>
    </div>
  );
}
