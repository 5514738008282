import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { ConfettiSection } from "../ConfettiSection/ConfettiSection";
import { HeaderForm } from "../HeaderForm/HeaderForm";
import { ImageDecoration } from "../ImageDecoration/ImageDecoration";
import { ConfirmedMessage } from "../ConfirmedMessage/ConfirmedMessage";
import { MainFormContainer } from "../MainFormContainer/MainFormContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import db from "../../firebase";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { collection, getDoc, setDoc, doc } from "firebase/firestore";

export const FormSection = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [isComing, setIsComing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const INITIAL_FORM_STATE = {
    fullName: "",
    email: "",
    phone: "",
    isComing: "Yes",
  };

  const FORM_VALIDATION = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email("Correo invalido.").required("Required"),
    phone: Yup.number()
      .integer()
      .typeError("Por favor ingrese un número de teléfono válido")
      .required("Required"),
  });

  const onSubmit = async (values) => {
    // alert(JSON.stringify(values, null, 2));
    setIsLoading(true);

    if (values.isComing === "Yes") {
      setIsComing(true);
    } else {
      setIsComing(false);
    }

    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      isComing: values.isComing,
    };

    //const dbRef = ref(getDatabase());

    // const citiesRef = collection(db, "confirmacionAsistencia");
    //
    // Create a query against the collection.
    // const q = query(citiesRef, where("email", "==", "julymanrij@gmail.com"));
    //console.log("resultado", q);

    const citiesRef = collection(db, "confirmacionAsistencia");

    await setDoc(doc(citiesRef, values.fullName), {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      isComing: values.isComing,
      dateCreate: new Date(),
    })
      .then(() => {
        setSuccessMessage(true);
        // Data saved successfully!
      })
      .catch((error) => {
        setErrorMessage(true);
        // The write failed...
      });
  };

  return (
    <Box
      id="confirmar-asistencia"
      sx={{
        pt: "8rem",
        pb: "8rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <ConfettiSection isExploding={isExploding} />
      {successMessage ? (
        <ConfirmedMessage isComing={isComing} />
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            textAlign: "center",
          }}
        >
          <HeaderForm />
          {/* <ImageDecoration /> */}
          <Box
            sx={{
              mt: "4rem",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                <MainFormContainer
                  INITIAL_FORM_STATE={INITIAL_FORM_STATE}
                  FORM_VALIDATION={FORM_VALIDATION}
                  isLoading={isLoading}
                  isExistingUser={isExistingUser}
                  onSubmit={onSubmit}
                />
              </Grid>
            </Box>
          </Box>
          {errorMessage && (
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  color: "red",
                }}
              >
                Ocurrió un error. Intente nuevamente.
              </Typography>
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
};
