import { ListItem, ListItemButton, Typography } from "@mui/material";
import React from "react";

const links = [
  { href: "#confirmar-asistencia", label: "Confirmar Asistencia" },
  { href: "#regalos", label: "Regalos" },
  { href: "#ubicacion", label: "Ubicación" },
  { href: "#cuando", label: "Cuando" },
  { href: "#nuestra-historia", label: "Nuestra Historia" },
];

const NavItemMobile = () => {
  return (
    <>
      {links.map((link) => {
        return (
          <ListItem key={link.key} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Typography
                component="a"
                href={link.href}
                variant="p"
                sx={{ display: { sm: "block", md: "none" } }}
              >
                {link.label}
              </Typography>
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default NavItemMobile;
