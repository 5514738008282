import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";

import React from "react";

const CORE_VALUES = [
  {
    year: "Agosto 2015",
    title: "Cuando nos conocimos",
    description: "Nos conocimos en el trabajo.",
    img: "/entrevista-online.png",
  },
  {
    year: "Enero 2018",
    title: "Cuando nos hicimos novios",
    description: "Nos hicimos novios en año nuevo a las 12:00 am",
    img: "/pareja.png",
  },
  {
    year: "Junio 2019",
    title: "Nos mudamos juntos",
    description:
      "Empezamos una nueva aventura los tres.",
    img: "/casa.png",
  },
  {
    year: "Octubre 2020",
    title: "Se creció la familia",
    description:
      "Llegó Nicolás!!",
    img: "/recien-nacido.png",
  },
  {
    year: "Enero 2023",
    title: "Dije si !!",
    description:
      "En año nuevo, Cristian se arrodilló.",
    img: "/anillo-de-bodas.png",
  },
  {
    year: "Mayo 2023",
    title: "Día de la boda",
    description: `Estamos muy emocionados de que te unas a nosotros mientras decimos "Sí, acepto" en nuestra boda. ¡Nos vemos pronto!`,
    img: "/boda.png",
  },
];

export const HistorySection = () => {
  return (
    <>
      <Box
        id="nuestra-historia"
        sx={{
          backgroundImage: `url(${"/wavetop.svg"})`,
          height: { xs: "200px", md: "340px" },
          width: 1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#b7d1ed",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            textAlign: "center",
          }}
        >
          <Stack
            spacing={3}
            sx={{
              maxWidth: 480,
              textAlign: "center",
              mx: "auto",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mt: { sx: "none", md: "2rem" },
                mb: "5rem",
              }}
            >
              Nuestra Historia
            </Typography>
          </Stack>

          <Timeline position={"alternate"}>
            {CORE_VALUES.map((value, index) => {
              const { title, description, year, img } = value;
              return (
                <TimelineItem
                  key={title}
                  sx={{
                    "&:before": {
                      display: { xs: "none", md: "block" },
                    },
                  }}
                >
                  <TimelineSeparator>
                    <TimelineDot
                      sx={{ color: "#FFF", backgroundColor: "#FFF" }}
                    />
                    <TimelineConnector
                      sx={{ color: "#FFF", backgroundColor: "#FFF" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent sx={{ pb: { xs: 3, md: 5 } }}>
                    <Typography variant="subtitle1">{year}</Typography>
                    <Paper
                      sx={{
                        p: "1.5rem",
                        mt: "2rem",
                        backgroundColor: "#FCFFF7",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ flex: 2, ml: "10px", mr: "10px" }}>
                          <Typography
                            variant="h3"
                            sx={{
                              mt: 0.5,
                              mb: 1,

                              textAlign: { xs: "center", sm: "left" },
                            }}
                          >
                            {title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              textAlign: { xs: "center", sm: "left" },
                              mt: "1rem",
                              mb: { xs: "1rem", sm: "0" },
                            }}
                          >
                            {description}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                          }}
                        >
                          <img src={img} alt="image" />
                        </Box>
                      </Box>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${"/waveDown.svg"})`,
          height: "380px",
          width: 1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          mb: "1rem",
        }}
      ></Box>
    </>
  );
};
