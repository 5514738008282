import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

export const Initial = () => {
  return (
    <Box
      id="initial"
      sx={{
        marginTop: { xs: "5.5rem" },
        backgroundImage: `url(${"/fondo.png"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        height: { xs: "400px", sm: "661px", md: "661px" },
      }}
    >
      <Box
        sx={{
          pt: { xs: "8rem", sm: "5rem" },
          pb: { xs: "2rem", sm: "8rem" }, 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Container
          sx={{
            textAlign: "center",
            width: "auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: { xs: "237px", sm: "186px", md: "100%" },
              textAlign: { xs: "-webkit-center" },
              fontFamily: "BrittanySignature",
              mb: "3rem",
              mt: "5rem",
              fontSize: { xs: "4.4rem", sm: "3.4rem", md: "4.1rem" },
            }}
          >
            Juliana & Cristian
          </Typography>

          <Typography
            variant="h1"
            sx={{
              width: { xs: "100%", sm: "186px", md: "100%" },
              textAlign: { xs: "center" },
              mb: "3rem",
              mt: "5rem",
              fontSize: { xs: "1.4rem", sm: "2.1rem", md: "2.1rem" },
            }}
          >
            Nos Casamos!
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};
