import { Typography } from "@mui/material";
import React from "react";

const linksRight = [
  { href: "#cuando", label: "Cuando" },
  { href: "#nuestra-historia", label: "Nuestra Historia" },
  { href: "#ubicacion", label: "Ubicación" },
];

const NavLinksRight = () => {
  return (
    <>
      {linksRight.map((link) => {
        return (
          <Typography
            key={link.label}
            component="a"
            href={link.href}
            sx={{
              ml: "35px",
              color: "#212B36",
              textDecoration: "none",
              borderBottom: "2px solid transparent",
              ":hover": {
                borderBottomColor: "##c7bbdf",
              },
            }}
          >
            {link.label}
          </Typography>
        );
      })}
    </>
  );
};

export default NavLinksRight;
