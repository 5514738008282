import { Box } from "@mui/material";
import React from "react";
import { Typography } from "@mui/material";

const Logo = () => {
  return (
    <Box
      sx={{
        display: { xs: "none", sm: "none", md: "block" },
        width: "100px",
        ml: "35px",
        mt: "10px",
      }}
    >
      <Typography
        component="a"
        href="#"
       
      >
        <img src="/logo.png" alt="Logo" />
      </Typography>
    </Box>
  );
};

export default Logo;
