import { Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";

export const ConfirmedMessage = ({ isComing }) => {
  const confirmationText = isComing
    ? "Gracias por confirmar su asistencia a nuestra boda. Estamos encantados de saber que nos acompañará en nuestro día especial. Estamos ansiosos por celebrar este nuevo capítulo de nuestras vidas junto con todos ustedes. ¡Gracias nuevamente por su apoyo y esperamos verlos pronto!"
    : "Gracias por informarnos que no podrá asistir a nuestra boda. Te extrañaremos, pero entendemos que a veces surgen cosas. Esperamos verte pronto.";
  return (
    <Grid container>
      <Grid item xs={12}>
        <Container maxWidth="md">
          <Paper
            sx={{
              pt: "4rem",
              pb: "4rem",
              pl: { xs: "0", md: "2rem" },
              pr: { xs: "0", md: "2rem" },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: "1rem",
                textAlign: "center",
              }}
            >
              Gracias por su respuesta.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: "50px",
                textAlign: "center",
              }}
            >
              {confirmationText}
            </Typography>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};
